// extracted by mini-css-extract-plugin
export var root = "PlasmicRemember-module--root--8iHRe";
export var h1 = "PlasmicRemember-module--h1--98hxV";
export var freeBox = "PlasmicRemember-module--freeBox--LCAjx";
export var columns = "PlasmicRemember-module--columns--bWNXI";
export var columnsglobal_mode_reveal = "PlasmicRemember-module--columnsglobal_mode_reveal--PqaeZ";
export var column__v0Llo = "PlasmicRemember-module--column__v0Llo--9eYOh";
export var case1 = "PlasmicRemember-module--case1--X8l8V";
export var case1global_mode_reveal = "PlasmicRemember-module--case1global_mode_reveal--XNRch";
export var img__yrMAw = "PlasmicRemember-module--img__yrMAw--LNc2q";
export var __wab_imgSpacer = "PlasmicRemember-module--__wab_img-spacer--Ete4S";
export var imgglobal_mode_reveal__yrMAwv94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__yrMAwv94Jk--Pes8O";
export var text___0SXGf = "PlasmicRemember-module--text___0SXGf--Jk-yu";
export var workCard__dgCuN = "PlasmicRemember-module--workCard__dgCuN--f9N0L";
export var workCardglobal_mode_reveal__dgCuNv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal__dgCuNv94Jk--M3tqR";
export var img__j8E8P = "PlasmicRemember-module--img__j8E8P--JIbzB";
export var imgglobal_mode_reveal__j8E8Pv94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__j8E8Pv94Jk--swBak";
export var workCard__eN9Yd = "PlasmicRemember-module--workCard__eN9Yd--j+j4h";
export var workCardglobal_mode_reveal__eN9YDv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal__eN9YDv94Jk--e53yl";
export var img__lAitF = "PlasmicRemember-module--img__lAitF--odQrd";
export var imgglobal_mode_reveal__lAitFv94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__lAitFv94Jk--5zMWX";
export var workCard___0NGeK = "PlasmicRemember-module--workCard___0NGeK--iOr5-";
export var workCardglobal_mode_reveal___0NGeKv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal___0NGeKv94Jk--Sxb5U";
export var img__doT7G = "PlasmicRemember-module--img__doT7G--t2Okx";
export var imgglobal_mode_dark__doT7GJYvh = "PlasmicRemember-module--imgglobal_mode_dark__doT7GJYvh--v31Hz";
export var imgglobal_mode_reveal__doT7Gv94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__doT7Gv94Jk--aRVhc";
export var workCard__ayaBx = "PlasmicRemember-module--workCard__ayaBx---DBYQ";
export var workCardglobal_mode_reveal__ayaBxv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal__ayaBxv94Jk--JkScP";
export var img__lXTlN = "PlasmicRemember-module--img__lXTlN--n7GM7";
export var imgglobal_mode_reveal__lXTlNv94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__lXTlNv94Jk--+eeFa";
export var column__amWpZ = "PlasmicRemember-module--column__amWpZ--Y17df";
export var workCard___6RwPh = "PlasmicRemember-module--workCard___6RwPh--3rpnC";
export var workCardglobal_mode_reveal___6RwPHv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal___6RwPHv94Jk--Z09S6";
export var img__mWe99 = "PlasmicRemember-module--img__mWe99--dzJw4";
export var imgglobal_mode_reveal__mWe99V94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__mWe99V94Jk--MKn9D";
export var workCard__mkFgA = "PlasmicRemember-module--workCard__mkFgA--LPy-D";
export var workCardglobal_mode_reveal__mkFgAv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal__mkFgAv94Jk--+ts1a";
export var img__kv7Eq = "PlasmicRemember-module--img__kv7Eq--P6UPQ";
export var imgglobal_mode_reveal__kv7EQv94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__kv7EQv94Jk--NsJy1";
export var workCard__bMvGu = "PlasmicRemember-module--workCard__bMvGu--LVGgs";
export var workCardglobal_mode_reveal__bMvGuv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal__bMvGuv94Jk--L-wAi";
export var img__fKio8 = "PlasmicRemember-module--img__fKio8--4E--l";
export var imgglobal_mode_reveal__fKio8V94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__fKio8V94Jk--ATaLB";
export var text__utVr0 = "PlasmicRemember-module--text__utVr0--Jrc+V";
export var workCard__srwfn = "PlasmicRemember-module--workCard__srwfn--odZ5d";
export var workCardglobal_mode_reveal__srwfNv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal__srwfNv94Jk--q6ZDP";
export var img__vbt9P = "PlasmicRemember-module--img__vbt9P--ALATI";
export var imgglobal_mode_reveal__vbt9Pv94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__vbt9Pv94Jk--ADgck";
export var workCard__h1ZKj = "PlasmicRemember-module--workCard__h1ZKj--MKR3w";
export var workCardglobal_mode_reveal__h1ZKJv94Jk = "PlasmicRemember-module--workCardglobal_mode_reveal__h1ZKJv94Jk--9dXls";
export var img__gXkj = "PlasmicRemember-module--img__gXkj--+Vtev";
export var imgglobal_mode_dark__gXkjjYvh = "PlasmicRemember-module--imgglobal_mode_dark__gXkjjYvh--7u38O";
export var imgglobal_mode_reveal__gXkJv94Jk = "PlasmicRemember-module--imgglobal_mode_reveal__gXkJv94Jk--gl4ud";
export var nav = "PlasmicRemember-module--nav--aKIZw";
export var reveal = "PlasmicRemember-module--reveal--VPleh";
export var revealglobal_mode_reveal = "PlasmicRemember-module--revealglobal_mode_reveal--XFCH+";
export var revealglobal_highlight_work = "PlasmicRemember-module--revealglobal_highlight_work--+ALYx";
export var svg___7EfG1 = "PlasmicRemember-module--svg___7EfG1--q7V1w";
export var svgglobal_mode_reveal___7EfG1V94Jk = "PlasmicRemember-module--svgglobal_mode_reveal___7EfG1V94Jk--D2wGT";
export var backHome = "PlasmicRemember-module--backHome--+RcRN";
export var svg__gg5Bj = "PlasmicRemember-module--svg__gg5Bj--DONeO";
export var svgglobal_mode_dark__gg5BjJYvh = "PlasmicRemember-module--svgglobal_mode_dark__gg5BjJYvh--6vI0w";
export var svgglobal_mode_reveal__gg5Bjv94Jk = "PlasmicRemember-module--svgglobal_mode_reveal__gg5Bjv94Jk--8hpWf";
export var svg__cikJ = "PlasmicRemember-module--svg__cikJ--g7DAD";
export var svgglobal_mode_dark__cikJJYvh = "PlasmicRemember-module--svgglobal_mode_dark__cikJJYvh--cenS7";
export var svgglobal_mode_reveal__cikJv94Jk = "PlasmicRemember-module--svgglobal_mode_reveal__cikJv94Jk--PUmWc";